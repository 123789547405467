export function FooterComponent() {
  return (
    <footer className="p-4 bg-white md:p-8 lg:p-10 dark:bg-gray-800">
      <div className="mx-auto max-w-screen-xl text-center">
        <a
          href="https://stream12.com"
          className="flex justify-center items-center text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <img
            src="https://stream12.com/wp-content/uploads/2023/11/stream12-150x150.jpg"
            className="mr-3 h-6 sm:h-9"
            alt="Stream 12 Logo"
          />
          Stream 12
        </a>
        <p className="my-6 text-gray-500 dark:text-gray-400">
          Multicast live streaming made simple.
        </p>
        <ul className="flex flex-wrap justify-center items-center mb-6 text-gray-900 dark:text-white">
          <li>
            <a
              href="https://stream12.com"
              className="mr-4 hover:underline md:mr-6 "
            >
              About
            </a>
          </li>
          <li>
            <button className="mr-4 hover:underline md:mr-6">Pricing</button>
          </li>
          <li>
            <a
              href="https://stream12.com/getting-started/"
              className="mr-4 hover:underline md:mr-6 "
            >
              Get Started
            </a>
          </li>
          <li>
            <a
              href="https://stream12.com/blog/"
              className="mr-4 hover:underline md:mr-6"
            >
              Blog
            </a>
          </li>
        </ul>
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
          &copy; {new Date().getFullYear()}{" "}
          <button className="hover:underline">
            Stream 12 by Kitten Monster, LLC
          </button>
          . All Rights Reserved.
        </span>
      </div>
    </footer>
  );
}

export default FooterComponent;
