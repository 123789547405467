import { Routes, Route } from "react-router-dom";
import MainLayout from "./components/layouts/MainLayout";
import HomeScreen from "./components/screens/HomeScreen";
import { AppRoutes } from "./utils/AppRoutes";
import LoginScreen from "./components/screens/LoginScreen";
import Register from "./components/pages/register/Register";

const getMainLayout = () => {
  return (
    <MainLayout>
      <Routes>
        <Route path={AppRoutes.HOME} element={<HomeScreen />} />
        <Route path={AppRoutes.ANY} element={<HomeScreen />} />
        <Route path={AppRoutes.SIGNUP} element={<Register />} />
        <Route index element={<LoginScreen />} />
      </Routes>
    </MainLayout>
  );
};

function App() {
  return <>{getMainLayout()}</>;
}

export default App;
