import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import AllStreamStateInterface from "../../interface/AllStreamStateInterface";

const initialState: AllStreamStateInterface = {
  streams: [],
  isLoading: false,
  isSuccessful: false,
  error: {},
};

export const allStreamSlice = createSlice({
  name: "allStream",
  initialState,
  reducers: {
    getAllStreamRequestAction: (state: any) => {
      state.isLoading = true;
    },
    getAllStreamSuccessAction: (state: any, action: PayloadAction<[]>) => {
      state.streams = action.payload;
      state.isLoading = false;
      state.isSuccessful = true;
    },
    getAllStreamFailedAction: (state: any, action: PayloadAction<{}>) => {
      state.isSuccessful = false;
      state.result = action.payload;
    },
  },
});

export const {
  getAllStreamRequestAction,
  getAllStreamSuccessAction,
  getAllStreamFailedAction,
} = allStreamSlice.actions;

export default allStreamSlice.reducer;
