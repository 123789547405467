import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllStreamRequestAction } from "../../../redux/reducer/allStreamSlice";
import { StreamInfoInterface } from "../../../interface/StreamInfoInterface";

export function HomeComponent() {
  const allStreamsState = useSelector((state: any) => state.allStream);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllStreamRequestAction());
  }, [dispatch]);

  return (
    <>
      <div className="grid grid-cols-3 gap-3 p-2">
        {allStreamsState?.streams.map(
          (item: StreamInfoInterface, index: number) => {
            return (
              <div
                className="flex flex-col justify-center items-center hover:cursor-pointer border shadow"
                key={index}
              >
                <img className="h-65 w-60" alt="" src={item.image} />
                <span>{`PRICE: ${item.price}`}</span>
                <span>{item.title}</span>
              </div>
            );
          }
        )}
      </div>
    </>
  );
}

export default HomeComponent;
