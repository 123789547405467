import LoginComponent from "../pages/login/LoginComponent";

const LoginScreen = () => {
  return (
    <>
      <LoginComponent />
    </>
  );
};

export default LoginScreen;
