import { catchError, from, map, mergeMap, of } from "rxjs";
import { ofType } from "redux-observable";
import FakeBackendService from "../../service/FakeBackendService";
import {
  getAllStreamFailedAction,
  getAllStreamRequestAction,
  getAllStreamSuccessAction,
} from "../reducer/allStreamSlice";

export const allStreamRequestEpic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(getAllStreamRequestAction),
    mergeMap((action: any) =>
      from(FakeBackendService.getAllStream()).pipe(
        map((response: any) => {
          if (response.data) {
            return getAllStreamSuccessAction(response.data);
          } else {
            throw response;
          }
        }),
        catchError((err) => {
          let result = {
            message: err,
          };
          return of(getAllStreamFailedAction(result));
        })
      )
    )
  );
};
