import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import { auth, signInWithGoogle } from "../../../environment/firebase";
import "./Register.css";
function Register() {
  const [user, loading] = useAuthState(auth);
  useEffect(() => {
    if (loading) return;
    if (user) window.location.href = "/dashboard";
  }, [user, loading]);
  return (
    <div className="register">
      <div className="register__container">
        <button
          className="register__btn register__google"
          onClick={signInWithGoogle}
        >
          Register with Google
        </button>
        <div>
          Already have an account? <Link to="/login">Login</Link> now.
        </div>
      </div>
    </div>
  );
}
export default Register;
