import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyBix7pttrlC6ZQs1PYsz7-z3vYPGfFQcsE",
  authDomain: "app.stream12.com",
  // authDomain: "academic-pier-338422.firebaseapp.com",
  databaseURL: "https://academic-pier-338422-default-rtdb.firebaseio.com",
  projectId: "academic-pier-338422",
  storageBucket: "academic-pier-338422.appspot.com",
  messagingSenderId: "567365440720",
  appId: "1:567365440720:web:a8d3dbaaf3d4c4fa95bad5",
  measurementId: "G-CTVYMPJ9VY",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err: any) {
    console.error(err);
    alert(err.message);
  }
};
const logout = () => {
  signOut(auth);
};
export { auth, db, signInWithGoogle, logout };
